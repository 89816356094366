import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const CoursesRetreatsAndSpecialEvents = () => (
  <Layout>
    <Seo title="Courses, Retreats, and Special Events" />

    <h1 id="top">Courses, Retreats and Special Events</h1>


      <p>Marianne’s offerings include:
        <ul>
          <li>Introduction to Mindfulness Meditation</li>
          <li>Mindful Relating: Presence of Mind and Heart</li>
          <li>Being Outdoors: Sensing the Sacred</li>
          <li>Eye of the Hurricane: Mindful Relationship with Personal and Global Change</li>
          <li>Fierce Compassion: Setting Boundaries</li>
          <li>Peace from the Inside Out: Mindful Relationship with Anxiety, Depression and Anger</li>
          <li>Half-day, Day-long and Extended retreats (including Spiritual Activism, Cultivating Compassion, Awakening to Interbeing, and Coherence: Living Your Values)</li>
          <li>Experiential Book Study Groups (including <i>Choosing Earth</i>, <i>Revolutionary Love</i>, and <i>Caste</i>)</li>

        </ul>
      </p>

      <p>You can find a listing of Marianne’s current and upcoming offerings here: <a href="https://www.montereybaymeditation.com"> Monterey Bay Meditation Studio</a>.</p>

      <p>Her intention in all of these offerings is to provide opportunities for learning, discussion, practice and creative expression, in service of evolving consciousness and deepening compassionate relationship with oneself, other humans, Earth, and all beings.</p>

      <p>
        <span style={{'textDecoration':'underline'}}>Training and Teaching Background</span></p>
<p>Marianne has been a student and practitioner of meditation since the 1990’s. She has studied with a number of teachers including His Holiness the XIV Dalai Lama, Pema Chodron, Adyashanti, Jack Kornfield, Cynthia Bourgeault, and Andrew Holicek. Her primary course of study has been in the Tibetan Buddhism tradition, with study and experience also in the mystical Christianity and Sufism traditions.

      </p>

      <p>In 2007, she developed curriculums for teaching mindfulness meditation in schools (Kindergarten through Graduate level) and founded Mindful Education Project. Since then, she has taught these curriculums in a number of Monterey County public and private schools including Sherwood Elementary, York School, CSUMB, and Middlebury Institute of International Studies, Monterey.
</p>

<p>In 2016, she co-founded Monterey Bay Meditation Studio and has been teaching courses, facilitating retreats, and creating events through MBMS ever since.
</p>



              {/* <p>
                Marianne has been a student and practitioner of meditation since the 1990’s. She has studied with a number of teachers including His Holiness the XIV Dalai Lama, Pema Chodron, Adyashanti, Jack Kornfield, Cynthia Bourgeault, and Andrew Holicek. Her primary course of study has been in the Tibetan Buddhism tradition, with study and experience also in the mystical Christianity and Sufism traditions.
              </p>

              <p>
                In 2007, she developed curriculums for teaching mindfulness meditation in schools (Kindergarten through Graduate level) and founded Mindful Education Project. Since then, she has taught these curriculums in a number of Monterey County public and private schools including Sherwood Elementary, York School, CSUMB, and Middlebury Institute of International Studies, Monterey.

              </p>

              <p>
                In 2016, she co-founded Monterey Bay Meditation Studio and has been teaching courses, facilitating retreats, and creating events through MBMS ever since. Marianne’s offerings include Introduction to Mindfulness Meditation; Mindful Relating; Being Outdoors: Sensing the Sacred; Shifting Gears: Mindful Flow through Change and Uncertainty; Fierce Compassion: Setting Boundaries; Peace from the Inside Out: Mindful Relationship with Anxiety, Depression and Anger; day-long and extended retreats (Cultivating Compassion, Awakening to Interbeing, and Coherence: Living Your Values); and several experiential book study groups. Her intention in all of these offerings is to provide opportunities for learning, discussion, practice and creative expression, in service of evolving consciousness and deepening compassionate relationship with oneself, other humans, Earth, and all beings.

              </p>

              <p>
                You can find a listing of Marianne’s current and upcoming offerings here: <a href="http://montereybaymeditation.com">Monterey Bay Meditation Studio</a>.

              </p> */}



  </Layout>
)

export default CoursesRetreatsAndSpecialEvents
